<template>
  <v-layout vertical class="d-flex flex-column">
    <i18n class="d-block text-caption mt-2" path="checkout.warning"></i18n>
    <AcceptRulesText />
    <v-row
      no-gutters
      class="mt-5"
      justify="space-between"
      :class="$vuetify.breakpoint.xs ? 'order-first' : 'order-last'"
    >
      <v-col cols="6" class="pr-1 d-flex justify-start">
        <v-tooltip top open-on-hover>
          <template v-slot:activator="{ on, attrs }"
            ><v-btn
              color="accent"
              class="white--text px-1 px-sm-auto"
              v-bind="attrs"
              v-on="on"
              :min-width="$vuetify.breakpoint.xs ? 'auto' : 200"
              :block="$vuetify.breakpoint.xs"
              large
              depressed
              :disabled="profileLevel < minProfileLevel || cart.totalItems == 0"
              @click="fastPayment"
            >
              {{ $t("checkout.fastPaymentBtn") }}
            </v-btn></template
          >
          <span v-html="$t('checkout.fastPaymentBtnTooltip')" />
        </v-tooltip>
      </v-col>

      <v-col cols="6" class="pl-1 d-flex justify-end">
        <v-btn
          color="primary"
          class="px-1 px-sm-auto"
          :min-width="$vuetify.breakpoint.xs ? 'auto' : 200"
          :block="$vuetify.breakpoint.xs"
          large
          depressed
          :disabled="profileLevel < minProfileLevel || cart.totalItems == 0"
          :loading="loading"
          @click="goToPayment"
        >
          {{ $t("checkout.goToPaymentBtn") }}
        </v-btn>
      </v-col>

      <v-row no-gutters class="mt-5" justify="space-between">
        <v-col cols="6" class="pr-1 d-flex justify-start">
          <v-btn
            color="secondary"
            class="px-1 px-sm-auto"
            :min-width="$vuetify.breakpoint.xs ? 'auto' : 200"
            :block="$vuetify.breakpoint.xs"
            large
            outlined
            :disabled="profileLevel < minProfileLevel || cart.totalItems == 0"
            @click="addAllToFavorites"
          >
            {{ $t("checkout.addToListBtn") }}
          </v-btn></v-col
        >

        <v-col cols="6" class="pl-1 d-flex justify-end">
          <v-btn
            v-if="isBetaTester"
            color="secondary"
            class="px-1 px-sm-auto"
            :min-width="$vuetify.breakpoint.xs ? 'auto' : 200"
            :block="$vuetify.breakpoint.xs"
            large
            outlined
            :disabled="profileLevel < minProfileLevel || cart.totalItems == 0"
            @click="emptyCartConfirm"
          >
            {{ $t("checkout.emptyCartBtn") }}
          </v-btn></v-col
        >
      </v-row>
    </v-row>
  </v-layout>
</template>

<script>
import AcceptRulesText from "@/components/payment/AcceptRulesText.vue";

import { mapGetters } from "vuex";
export default {
  props: {
    loading: { type: Boolean, default: false },
    cart: { type: Object, required: true }
  },
  components: {
    AcceptRulesText
  },
  data() {
    return {
      minProfileLevel: global.config.minProfileLevel
    };
  },
  computed: {
    ...mapGetters({
      profileLevel: "cart/profileLevel",
      isBetaTester: "cart/isBetaTester"
    })
  },
  methods: {
    addAllToFavorites() {
      this.$emit("addAllToFavorites");
    },
    emptyCartConfirm() {
      this.$emit("emptyCartConfirm");
    },
    fastPayment() {
      this.$emit("fastPayment");
    },
    goToPayment() {
      this.$emit("goToPayment");
    }
  }
};
</script>
