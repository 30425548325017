<template>
  <div class="px-0 px-sm-5">
    <!-- Blocco header -->
    <v-container fluid v-if="headerProposals.length > 0" class="header mb-3">
      <div class="mt-5">
        <component
          :is="headerMode"
          :title="headerTitle"
          :proposals="headerProposals"
          :autoplay="true"
          paginationClass="pagination-header"
          cols="auto"
          sm="auto"
          md="auto"
          lg="auto"
        />
      </div>
    </v-container>
    <v-container>
      <CategoryTitle :category="category" :showOnMobile="true" />
      <v-row no-gutters>
        <v-col cols="12" md="8" lg="9" class="pt-0 pr-0 pr-md-3">
          <v-row no-gutters>
            <div class="d-flex w-100 flex-column pa-3 white mb-3 rounded-md">
              <v-row no-gutters justify="space-between">
                <!-- CartInfoServiceCard nascosto in mobile -->
                <v-col
                  cols="12"
                  sm="4"
                  class="pr-0 pr-sm-3 mb-3 mb-sm-0 d-none d-sm-flex"
                >
                  <!-- CartInfoServiceCard nascosto in mobile -->
                  <CartInfoServiceCard
                    :shippingAddress="cart.shippingAddress"
                    :editable="editableAddress"
                    @edit="openAddressSelector"
                    class="grey lighten-2 w-100"
                  />
                </v-col>
                <v-col cols="12" sm="4" class="pr-0 pr-sm-3 mb-3 mb-sm-0">
                  <CartInfoAddressCard
                    :shippingAddress="cart.shippingAddress"
                    :editable="editableAddress"
                    @edit="openAddressSelector"
                    class="grey lighten-2 w-100"
                  />
                </v-col>
                <v-col cols="12" sm="4" class="pr-0 pr-sm-3 mb-3 mb-sm-0">
                  <CartInfoTimeslotCard
                    :shippingAddress="cart.shippingAddress"
                    :timeslot="cart.timeslot"
                    :editable="editableTimeslot"
                    @edit="openTimeslotSelector"
                    class="grey lighten-2 w-100"
                  />
                </v-col>
              </v-row>
              <v-row no-gutters class="mt-0 mt-sm-6">
                <v-checkbox
                  color="secondary"
                  class="ma-0 mb-0 mb-sm-2"
                  :class="{ 'w-90': $vuetify.breakpoint.xs }"
                  hide-details
                  required
                  v-model="acceptAlternatives"
                  ><template v-slot:label>
                    <span
                      class="text-caption default--text text-lighten-1 font-weight-bold"
                    >
                      {{ $t("checkout.acceptAllAlternatives") }}
                    </span>
                  </template>
                </v-checkbox>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      x-small
                      color="secondary"
                      icon
                      fab
                      ><v-icon small>$infoFull</v-icon></v-btn
                    >
                  </template>
                  <span>{{ $t("checkout.acceptAlternativesTooltip") }}</span>
                </v-tooltip>
              </v-row>
            </div>
          </v-row>

          <!-- CheckoutSummary MOBILE (pulsanti e link finali non visibili in app) -->
          <CheckoutSummary
            class="checkout-summary d-flex flex-column d-sm-none"
            :orderCart="cart"
            :loading="loading"
            @removeGiftCode="removeUserGiftCode"
            @addGiftCode="addUserGiftCode"
            @sendGiftCode="sendUserGiftCode"
            :deliveryFee="deliveryFee"
            :giftCertificates="giftCertificates"
            @fastPayment="fastPayment"
            @goToPayment="goToPayment"
          />

          <div class="white rounded-md">
            <!-- elenco prodotti -->
            <CheckoutItemList :showHeader="true" :showSectors="true" />

            <!-- Pulsanti di completamento checkout (non visibili in app) -->
            <CheckoutActions
              class="pa-5"
              v-if="!isCordova"
              :loading="loading"
              :cart="cart"
              @addAllToFavorites="addAllToFavorites"
              @fastPayment="fastPayment"
              @goToPayment="goToPayment"
              @emptyCartConfirm="emptyCartConfirm"
            />
          </div>

          <v-alert
            v-if="error"
            type="error"
            border="left"
            transition="v-fab-transition"
          >
            {{ error }}
          </v-alert>
        </v-col>

        <v-col
          cols="12"
          md="4"
          lg="3"
          class="d-flex flex-column pt-5 pt-md-0 pl-0 pl-md-3"
        >
          <!-- blocco sideox -->
          <div
            class="sidebox-banner products-slider-full-width px-0 mt-0 mt-md-5"
            v-if="sideboxProposals.length > 0"
          >
            <component
              :is="sideboxMode"
              :title="sideboxTitle"
              :proposals="sideboxProposals"
              :autoplay="true"
              paginationClass="pagination-sidebox"
              cols="1"
              sm="1"
              md="1"
              lg="1"
            />
          </div>

          <!-- CheckoutSummary DESKTOP -->
          <CheckoutSummary
            class="checkout-summary d-none d-sm-flex flex-column"
            :orderCart="cart"
            :loading="loading"
            @removeGiftCode="removeUserGiftCode"
            @addGiftCode="addUserGiftCode"
            @sendGiftCode="sendUserGiftCode"
            :deliveryFee="deliveryFee"
            :giftCertificates="giftCertificates"
            @fastPayment="fastPayment"
            @goToPayment="goToPayment"
          />
        </v-col>
      </v-row>

      <!-- <div class="mt-8"> -->
      <ProductListSlider
        class="mt-8"
        :key="'hai_preso_tutto_footer2'"
        title="Hai preso tutto?"
        :layout="29"
        :autoplay="true"
        :limit="24"
        :paginationClass="'swiper-pagination-hai-preso-tutto'"
        cols="auto"
        sm="auto"
        md="auto"
        lg="auto"
      >
        <template #title>
          <div class="proposal-title-wrapper secondary lighten-3">
            <h2 class="proposal-title text-uppercase mb-3">
              Hai preso tutto?
            </h2>
          </div>
        </template>
      </ProductListSlider>
      <!-- </div> -->

      <!-- blocco footer -->
      <div class="footer mt-8" v-if="footerProposals.length > 0">
        <component
          :is="footerMode"
          :title="footerTitle"
          :proposals="footerProposals"
          :autoplay="true"
          paginationClass="pagination-footer-1"
          cols="auto"
          sm="auto"
          md="auto"
          lg="auto"
        />
      </div>
    </v-container>
    <!-- card delle azioni di checkout ancorato alla base della finestra (ma non si sovrappone al footer) per APP -->
    <CheckoutActions
      class="cordova-checkout-actions"
      v-if="isCordova"
      :loading="loading"
      :cart="cart"
      @addAllToFavorites="addAllToFavorites"
      @fastPayment="fastPayment"
      @goToPayment="goToPayment"
      @emptyCartConfirm="emptyCartConfirm"
    />

    <div class="checkout-warning-modal text-center">
      <v-dialog v-model="checkoutWarning" width="500">
        <v-card>
          <div class="d-flex justify-center">
            <v-img
              class="logo-modal"
              src="/img_layout/modal-logo.png"
              alt="Logo tosano"
              contain
              height="80px"
              width="80px"
            >
            </v-img>
          </div>
          <v-card-title class="d-flex justify-center">
            <h2>
              Attenzione
            </h2>
            <v-spacer></v-spacer>
            <div
              icon
              class="vuedl-layout__closeBtn"
              @click="checkoutWarning = false"
            >
              <v-icon>$close</v-icon>
            </div>
          </v-card-title>

          <v-card-text>
            <v-alert color="primary" text class="text-body-2"
              >Hai selezionato un punto vendita/indirizzo diverso da quello che
              abitualmente utilizzi.<br />
              Punto vendita/indirizzo selezionato:
              <strong>{{ cart.shippingAddress.addressName }}</strong
              >.<br />
              Punto vendita/indirizzo abituale:
              <strong>{{ favStoreName }}</strong
              >.</v-alert
            >
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed color="primary" @click="openAddressSelector">
              <v-icon small right>$edit</v-icon><span>MODIFICA</span>
            </v-btn>
            <v-btn depressed color="primary" @click="proceedToPayment">
              VAI ALLA CASSA
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<style scoped lang="scss">
.qty-wrap {
  max-width: 160px;
}

::v-deep .subtitle {
  @media #{map-get($display-breakpoints, 'xs-only')} {
    display: none !important;
  }
}

::v-deep .cart-info-card {
  padding: 20px 10px !important;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    padding: 5px 10px !important;
  }
  .edit {
    @media #{map-get($display-breakpoints, 'md-and-down')} {
      position: absolute;
      bottom: 10px;
      right: 10px;
    }
  }
}
@media #{map-get($display-breakpoints, 'md-and-up')} {
  .gift-code {
    order: 1;
  }
  .checkout-summary {
    order: 2;
    flex: 0;
  }
  .sidebox-banner {
    order: 3;
  }
}
.cordova-checkout-actions {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $white;
  padding: 5px 10px;
  z-index: 3;
  -webkit-box-shadow: 0px -5px 11px -7px #000000;
  box-shadow: 0px -5px 11px -7px #000000;
}
</style>
<script>
import CheckoutSummary from "@/components/cart/CheckoutSummary.vue";
import AcceptRulesText from "@/components/payment/AcceptRulesText.vue";

import CheckoutItemList from "@/components/cart/CheckoutItemList.vue";
import CheckoutActions from "@/components/cart/CheckoutActions.vue";
import FastPayment from "@/components/cart/FastPayment.vue";
import CartInfoServiceCard from "@/components/cart/CartInfoServiceCard.vue";
import CartInfoAddressCard from "@/components/cart/CartInfoAddressCard.vue";
import CartInfoTimeslotCard from "@/components/cart/CartInfoTimeslotCard.vue";
import ProductListSlider from "@/components/product/ProductListSlider.vue";
import ListSelector from "@/components/lists/ListSelector.vue";
import CategoryTitle from "@/components/category/CategoryTitle.vue";

import AnalyticsService from "~/service/analyticsService";
import RegistrationService from "~/service/userService";
import ListService from "~/service/listService";
import DeliveryService from "~/service/deliveryService";

import get from "lodash/get";
import reduce from "lodash/reduce";

import login from "~/mixins/login";
import cartInfo from "~/mixins/cartInfo";
import categoryMixins from "~/mixins/category";

import { mapActions, mapState, mapGetters } from "vuex";
import { forEachCartItem } from "~/service/ebsn.js";
import { mapProposalComponent } from "~/service/ebsn.js";

export default {
  name: "Checkout",
  mixins: [login, cartInfo, categoryMixins],
  components: {
    AcceptRulesText,
    CategoryTitle,
    CheckoutSummary,
    CheckoutItemList,
    CheckoutActions,
    CartInfoServiceCard,
    CartInfoAddressCard,
    CartInfoTimeslotCard,
    ProductListSlider,
    ProposalProductListSlider: () =>
      import("@/components/proposal/ProposalProductListSlider.vue"),
    ProposalProductGrid: () =>
      import("@/components/proposal/ProposalProductGrid.vue"),
    ProposalSlider: () => import("@/components/proposal/ProposalSlider.vue"),
    ProposalGrid: () => import("@/components/proposal/ProposalGrid.vue")
  },
  data() {
    return {
      error: null,
      loading: false,
      minProfileLevel: global.config.minProfileLevel,
      giftCertificates: [],
      emailKey: 1,
      packagesList: [],
      packages: true,
      deliveryFee: null,
      checkoutWarning: false,
      favStoreName: null
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    ...mapGetters({
      profileLevel: "cart/profileLevel",
      getCartStatus: "cart/getCartStatus",
      deliveryServiceId: "cart/getDeliveryServiceId"
    }),
    ...mapProposalComponent({
      header: "header",
      footer: "footer",
      sidebox: "sidebox"
    }),
    confirmed() {
      return get(this.cart, "user.profile.confirmed", false);
    },
    acceptAlternatives: {
      get() {
        let value = false;
        this.cart.cartItems.forEach(cartItem => {
          if (
            get(cartItem, "cartItemInfo.accept_alternatives", "true") == "false"
          ) {
            value = false;
          }
          return value;
        });
        reduce(
          this.cart.cartItems,
          function(value, cartItem) {
            return (value && cartItem.cartItemInfo.accept_alternatives) || true;
          },
          true
        );
        return value;
      },
      set(value) {
        this.setCartItemInfo({
          items: this.cart.cartItems,
          name: "accept_alternatives",
          value: "" + value
        });
      }
    },
    editableAddress() {
      return !(this.getCartStatus == 7);
    },
    editableTimeslot() {
      return !(this.$route.name == "Payment");
    }
  },

  methods: {
    ...mapActions({
      lockCart: "cart/lockCart",
      emptyCart: "cart/emptyCart",
      addGiftCode: "cart/addGiftCode",
      sendGiftCode: "cart/sendGiftCode",
      removeGiftCode: "cart/removeGiftCode",
      setCartItemInfo: "cart/setCartItemInfo",
      setCartInfo: "cart/setCartInfo"
    }),
    async emptyCartConfirm() {
      if (this.cart.totalItems > 0) {
        let res = await global.vm.$dialog.confirm({
          text: global.vm.$t("message.emptyCart")
        });
        if (res) {
          this.emptyCart();
        }
      }
    },
    async addUserGiftCode(giftCodeId) {
      let cart = await this.addGiftCode(giftCodeId);
      if (cart) {
        this.getUserDetails();
      }
    },
    async sendUserGiftCode(giftCodeId) {
      let cart = await this.sendGiftCode(giftCodeId);
      if (cart) {
        this.getUserDetails();
      }
    },
    async removeUserGiftCode(giftCodeId) {
      let cart = await this.removeGiftCode(giftCodeId);
      if (cart) {
        this.getUserDetails();
      }
    },
    async getUserDetails() {
      let _this = this;
      const data = await RegistrationService.getUserDetail();
      // merge with used certificates
      _this.giftCertificates = data.giftCertificatesReceived || [];
    },
    async addAllToFavorites() {
      let _this = this;
      let res = await _this.$dialog.show(ListSelector, {
        waitForResult: true,
        fullscreen: _this.$vuetify.breakpoint.smAndDown,
        width: 300
      });
      if (res) {
        let items = [];
        forEachCartItem(_this.cart, function(item) {
          items.push(item);
        });
        await ListService.addProductsToList(res.listId, items);
      }
    },
    async fastPayment() {
      let _this = this;
      if (!this.confirmed) {
        global.EventBus.$emit("error", {
          message: this.$t("checkout.confirmEmailSubTitle")
        });
      } else {
        let res = await _this.$dialog.show(FastPayment, {
          waitForResult: true,
          width: 500
        });
        if (res) {
          // let items = [];
          // forEachCartItem(_this.cart, function(item) {
          //   items.push(item);
          // });
          // await ListService.addProductsToList(res.listId, items);
        }
      }
    },
    async goToPayment() {
      let _this = this;
      if (
        (this.deliveryServiceId === 1 &&
          _this.cart.user.defaultStoreAddressId &&
          _this.cart.shippingAddress.addressId !=
            _this.cart.user.defaultStoreAddressId) ||
        (_this.cart.user.defaultDeliveryAddressId &&
          this.deliveryServiceId === 2 &&
          _this.cart.shippingAddress.addressId !=
            _this.cart.user.defaultDeliveryAddressId)
      ) {
        this.checkoutWarning = true;
      } else {
        await this.proceedToPayment();
      }
    },
    async proceedToPayment() {
      let _this = this;
      _this.loading = true;
      try {
        await _this.lockCart();
        _this.$router.push({
          name: "Payment"
        });
      } catch (err) {
        await this.needTimeslot();
        _this.error = err.message;
      } finally {
        _this.loading = false;
      }
    },
    async getDeliveryFee() {
      this.deliveryFee =
        this.deliveryServiceId == 2
          ? await DeliveryService.getDeliveryFee()
          : (this.deliveryFee = null);
    }
  },
  mounted() {
    this.checkoutWarning = false;
    global.EventBus.$on("resetAlertMessage", () => {
      if (this.error) {
        this.error = false;
      }
    });
  },
  async created() {
    AnalyticsService.beginCheckout(this.cart);
    this.getUserDetails();
    this.getDeliveryFee();
    if (this.cart.user.defaultStoreAddressId) {
      const favStore = await DeliveryService.getAddresById(
        this.cart.user.defaultStoreAddressId
      );
      this.favStoreName = favStore.addressName;
    }
    AnalyticsService.viewProducts(this.cart.cartItems, "Checkout", 0);
  },
  watch: {
    deliveryServiceId() {
      this.getDeliveryFee();
    }
  }
};
</script>
