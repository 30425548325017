<template>
  <div>
    <v-row
      no-gutters
      class="text-caption font-weight-bold mb-3"
      v-if="showHeader && $vuetify.breakpoint.smAndUp"
    >
      <v-col cols="5" lg="6" class="pl-3">
        {{ $t("checkout.header.product") }}
      </v-col>
      <v-col cols="7" lg="6">
        <v-row no-gutters>
          <v-col cols="3" class="text-center pr-0 pr-lg-3">
            {{ $t("checkout.header.unitPrice") }}
          </v-col>
          <v-col cols="6" class="text-center">
            {{ $t("checkout.header.qty") }}
          </v-col>
          <v-col cols="2" class="text-center">
            {{ $t("checkout.header.total") }}
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
      </v-col>
    </v-row>
    <CartItemList :full="true" :isCheckout="true" :showSectors="showSectors" />
  </div>
</template>
<style scoped lang="scss">
::v-deep .cur-price {
  width: 90px !important;
  padding: 10px !important;
  margin-right: 10px !important;
}
</style>
<script>
import CartItemList from "@/components/cart/CartItemList.vue";
export default {
  props: {
    showSectors: { type: Boolean, default: false },
    showHeader: { type: Boolean, default: false }
  },
  components: { CartItemList }
};
</script>
